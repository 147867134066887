<template>
    <div class="noPage">
        <a-result status="404" title="404" sub-title="抱歉，你访问的页面不存在或仍在开发中">
            <template #extra>
                <a-button @click="toHome">返回首页</a-button>
            </template>
        </a-result>
    </div>
</template>
<script>
import { mapState , mapActions } from "vuex";

export default {
    methods:{
        ...mapActions(['updateTabListAction','activeNameAction']),
        toHome(){
            this.updateTabListAction([{
                title:'主页面',
                path:'/about',
                name: 'About',
                isDetele:false
            }])
            this.activeNameAction('About')
            this.$router.push('/')
        }
    }
}
</script>
<style lang="less" scoped>
.noPage{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
